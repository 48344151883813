import axios from "axios";
import { getAuthorization } from "../utils/Auth"

async function getEquipmentDetails() {
  const url = `${process.env.REACT_APP_BASE_URL}/types?objectCode=${process.env.REACT_APP_EQUIPMENT_CODE}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorization(),
        },
      })
      .then((response) => {
        if (response != null) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const equipmentService = {
  getEquipmentDetails
}
