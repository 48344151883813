import axios from "axios";
import { getAuthorization } from "../utils/Auth"
import moment from 'moment';

async function getPreSignedUrl(file) {
  const filename = (moment().format("YYYY-MM-DD-HH:MM:SS") + file.name)
  const url = `${process.env.REACT_APP_BASE_URL}/mediabucket/?objectKey=` + filename;
  return new Promise((resolve, reject) => {
    axios
      .get(
        url,
        {
          headers: {
            Authorization: getAuthorization()
          },
        },
      )
      .then((response) => {
        if (response != null) {
          response.data.fileName = filename
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

const uploadFileToS3 = (presignedPostURL, file, fileName) => {
  // put the data on the s3 url
  return new Promise((resolve, reject) => {
    return axios.put(presignedPostURL, file, {
      headers: {
        'Content-Type': '',
      }
    }).then(function (response) {
      resolve({ "fileKey": fileName })
    })
      .catch(function (error) {
        console.log(error);
      });
  })

};

async function uploadPictures(file) {
  const presignedPostURL = await getPreSignedUrl(file)
  const fileKey = await uploadFileToS3(presignedPostURL.data.PreSignedURL, file, presignedPostURL.data.fileName)
  return fileKey;
}







export const uploadService = {
  uploadPictures
};
