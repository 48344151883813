import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './localization/i18n'

export const App = () => {
  return (
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <Router>
          <Routes />
        </Router>
      </I18nextProvider>
    </React.StrictMode>
  );
}
