export const getGobalStyle = () => {
    return {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            zIndex: 9999,
            mariginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderColor: 'rgb(0,0,0,0)',
            backgroundColor: 'rgb(0,0,0,0)',
        },
    }
}

export const getInstallModalStyle = () => {
    return {
        content: {
            top: '90%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            zIndex: 9999,
            mariginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderColor: 'rgb(0,0,0,0)',
            backgroundColor: '#6a6c6f',
            width: '80%'
        },
    }
}
