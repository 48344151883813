import axios from "axios";
import { getAuthorization } from "../utils/Auth"

async function getLocationDetails(locName) {
  const url = `${process.env.REACT_APP_BASE_URL}/searchlocations?locationArea=${locName}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorization()

        },
      })
      .then((response) => {
        if (response != null) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const locationService = {
  getLocationDetails
}
