import React from "react";
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { getInstallModalStyle } from '../../utils/GobalStyle';
import './IosInAppInstallation.css'
require('typeface-montserrat')

const styles = getInstallModalStyle();

const IosInstallModal = (props) => {
    const { t } = useTranslation()

    return (
        <Modal
            isOpen={props.modalIsOpen}
            onRequestClose={props.closeModal}
            style={styles}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
        >
            <div className="ios-popup">          
              <p className="pop_up">{t('iosInAppMsg')}</p>
            <div className="btn_popup">
                <button className="rectanglebutton"
                    onClick={props.closeModal} >
                    <span className="submit"> {t('ok')}</span>
                </button>
            </div>
            </div>

        </Modal>
    );
}
export default IosInstallModal;
