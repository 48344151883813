import React from "react";
import Modal from 'react-modal';
import loader from '../../assets/img/loading.gif';
import { getGobalStyle } from '../../utils/GobalStyle';


const loaderStyles = getGobalStyle();

const Loader = (props) => {

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={loaderStyles}
      ariaHideApp={false}
    >
      <img
        src={loader}
        alt="loading"
        className="image"
      />
    </Modal>
  );
}
export default Loader;
