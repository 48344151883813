import React, { useState } from "react";
import './Header.css';
import Switch from "react-switch";
import logo from '../../assets/img/logo.svg';
import { useTranslation } from 'react-i18next';
require('typeface-montserrat')

export const Header = () => {
  const { t, i18n } = useTranslation()
  const [, setSelectedLang] = useState("en")

  return (
    <div
      className="d-flex justify-content-between align-items-start logo-img">
      <div
        className="d-flex flex-column justify-content-center align-items-start header-image">
        <img
          src={logo}
          alt='logo'
          className="bitmap"
        />
        <a className="help-305-699-3956" href="tel:+305-699-3956">{t("Help")}</a>
      </div>
      <div
        className="d-flex justify-content-center align-items-center img-style"
        data-testid="langSwitch"
      >
        <Switch
          checked={i18n.language === "en"}
          onChange={(checked) => {
            if (checked) {
              i18n.changeLanguage('en');
              setSelectedLang("en")
            } else {
              i18n.changeLanguage('fr');
              setSelectedLang("fr")
            }
          }}
          offColor="#36AC47"
          onColor="#2C8BD4"
          className="switchText"
          uncheckedIcon={
            <div className="lang-name">
              <span className="esp"> ESP </span>
            </div>
          }
          checkedIcon={
            <div className="lang-name">
              <span className="eng"> ENG </span>
            </div>
          }
        />
      </div>

    </div>
  );
}
