import React from "react";
import "./Success.css";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/img/logo.svg';
import icon from '../../assets/img/icon.png';


require('typeface-montserrat')

export const Success = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const onsubmit = () => {
    history.replace({ pathname: '/' });
  }

  return (
    <div className="successContainer">
      <div className="container h-100 d-flex justify-content-center">
        <div className=" my-auto d-flex flex-wrap flex-column justify-content-center align-items-center mx-50">
          <img
            src={icon}
            alt='icon'
            className="iconimage"
          />
          <span className="thank-you">{t("thankYou")}</span>
          <p className="thanks-for-taking-th">
            {t("report")}
          </p>
          <button type="button"
            className="okbutton"
            onClick={onsubmit}>
            <span className="ok">{t('ok')}</span>
          </button>
          <img
            src={logo}
            alt='logo'
            className="map"
          />
        </div>
      </div>
    </div>
  );
}
