import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Confirm.css';
import { Header } from '../Header';
import { getLocationDetails } from "../../utils/Auth"
import { useHistory } from 'react-router-dom';

require('typeface-montserrat')

export const Confirm = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [locDetails,] = useState(getLocationDetails())

    const handleSubmit = () => {
        history.push({ pathname: '/laundryform', locDetails: locDetails })
    }

    const { name, displayLongName, city } = locDetails;
    return (
        <React.Fragment>
            <Header />
            <div className="confirm">
                <div className="row ">
                    <div className="col-md-12">
                        <div className="contact-form ">
                            <div className="form-group">
                                <div className="col-sm-12" data-testid="address">
                                    <h4 className="please-confirm-the-l">{t("confirmLoc")}</h4>
                                    <span className="address-line-city">{name}</span>
                                    <span className="address-line-city">{city}</span>
                                     <span className="address-line-city">{displayLongName}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group buttons" >
                    <div className="col-sm-12 buttonscontainer">
                        <button className="rectanglesearch"
                            type={"button"}
                            onClick={() => { history.goBack() }}
                        >
                            <span className="search-again"> {t("searchAgain")}</span></button>

                        <button
                            className="rectanglesub"
                            onClick={handleSubmit} type="submit" >
                            <span className="next">{t("next")}</span>
                        </button>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
