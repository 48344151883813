import axios from "axios";

async function getToken() {
  const url = `${process.env.REACT_APP_BASE_URL}/tokens`;
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {
          Username: process.env.REACT_APP_USERNAME,
          Password: process.env.REACT_APP_PASSWORD,
          ClientCode: process.env.REACT_APP_CLIENTCODE
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then((response) => {
        if (response != null) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const tokenService = {
  getToken
};
