export const getAuthorization = () => {
    const accessToken = JSON.parse(localStorage.getItem("accessToken"))
    return `${process.env.REACT_APP_AUTH_TYPE} ${accessToken.token}`
}

export const setToken = (token) => {
    localStorage.setItem("accessToken", JSON.stringify(token))
}

export const setLocationDetails = (locDetails) => {
    localStorage.setItem("locDetails", JSON.stringify(locDetails))
}

export const getLocationDetails = () => {
    return JSON.parse(localStorage.getItem("locDetails"));
}



export const setInstallOption = (value) => {
    localStorage.setItem("gaveInstallOption", value)
}

export const getInstallOption = () => {
    return JSON.parse(localStorage.getItem("gaveInstallOption"));
}


