import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import { Header } from '../Header';
import InstallModal from '../InAppInstallation'
import IosInstallModal from '../IosInAppInstallation';

import './Home.css';

//..............API................
import { tokenService } from './../../api/tokenService';
import { locationService } from './../../api/locationService';
import { setToken, setLocationDetails, getInstallOption, setInstallOption } from '../../utils/Auth'
import Loader from './../Loader/index';
import Swal from 'sweetalert2';

require('typeface-montserrat')

export const Home = () => {
  const history = useHistory();
  const { t } = useTranslation();
  let deferredPrompt = useRef();
  const [errMessage, setErrMessage] = useState("")
  const [locName, setLocName] = useState("")
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [installModal, setInstallModal] = useState(false)
  const [showInstallMessage, setShowInstallMessage] = useState(false)




  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const goToConfirm = async () => {
    setLocationDetails("")
    openModal()
    let locationData = []
    let err = "";
    if (locName.trim().length === 0) {
      err = <p className="error">{t("incorrect")}</p>;
      setErrMessage(err);
      closeModal()
    } else {
      await locationService.getLocationDetails(locName.trim())
        .then(async (res) => {
          closeModal()
          locationData = res.data.Entities;
          if (locationData.length === 0) {
            err = <p className="error">{t("not_found")}</p>;
            setErrMessage(err);
          } else if (locationData.length > 1) {
            err = <p className="error">{t("invalid_location")}</p>;
            setErrMessage(err);
          } else {
            setLocationDetails(locationData[0]);
            history.push({ pathname: '/confirm', locDetails: locationData[0] })
          }
        })
        .catch(() => {
          closeModal()
          Swal.fire({
            title: `<small> ${t('serverStatus')} </small>`,
            showCancelButton: false,
            showDenyButton: false,
            confirmButtonText: `<small> ${t('ok')} </small>`
          })
        });
    }
  }


  const userValue = (event) => {
    const n = event.target.name;
    const v = event.target.value;
    let err = "";
    if (n === "locName" && v !== "" && v.trim().length === 0) {
      err = <p className="error">{t("invalid")}</p>;
    }
    setErrMessage(err);
    setLocName(v.trim());
  };
  const getTokenAsync = useCallback(async () => {
    await tokenService
      .getToken()
      .then(async (res) => {
        let tokenData = res.data.accessToken;
        setToken(tokenData)
      })
      .catch((err) => {
        console.log(err)
        Swal.fire({
          title: `<small> ${t('serverStatus')} </small>`,
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: `<small> ${t('ok')} </small>`

        })
      });
  }, [t]);

  const hideInstallPromotion = () => {
    setInstallModal(false)
    setShowInstallMessage(false)
  }

  const onInstall = async () => {
    hideInstallPromotion()
    deferredPrompt.current.prompt();
    // Wait for the user to respond to the prompt
    const { outcome } = await deferredPrompt.current.userChoice;
    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`);
    if (outcome === "dismissed") {
      setInstallOption(1)
    }
    // We've used the prompt, and can't use it again, throw it away
    deferredPrompt.current = null;
  }

  const showInstallPromotion = () => {
    setInstallModal(true)
    setInstallOption(1)
  }
  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  // Checks if should display install popup notification:



  useEffect(() => {
    getTokenAsync()
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt.current = e;
      // Update UI notify the user they can install the PWA
      if (getInstallOption() === "0" || getInstallOption() === 0) {
        showInstallPromotion();
      } else {
        setInstallOption(0)
      }
      // Optionally, send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`);
    });

    window.addEventListener('appinstalled', () => {
      // Hide the app-provided install promotion
      hideInstallPromotion();
      // Clear the deferredPrompt so it can be garbage collected
      deferredPrompt.current = null;
      // Optionally, send analytics event to indicate successful install
      console.log('PWA was installed');
      setInstallOption(0)
    });

    if (isIos() && !isInStandaloneMode()) {
      setShowInstallMessage(true);
    }

    return () => {
      setInstallOption(0)
    }


  }, [getTokenAsync])


  return (
    <React.Fragment>
      <Header />
      <div className="home">
        <div className="row">
          <div className="col-md-12">
            <div className="contact-form">
              <form>
                <div className="form-group">
                  <article>
                    <h6 className="welcome-to-commercia" data-testid="welcome">{t("welcome")}</h6>
                    <span className="please-enter-the-loc">{t("registerLoc")}</span>
                  </article>
                  <div className="d-flex justify-content-between ">
                    <div className="errcontainer">
                      <input
                        type="text"
                        id="locName"
                        className="rectangle"
                        name="locName"
                        onChange={(e) => { userValue(e) }}
                        required
                      />
                      {errMessage}
                    </div>
                    <i className="fa fa-arrow-circle-o-right arrowicon"
                      onClick={() => { goToConfirm() }}
                    >
                    </i>
                  </div>
                </div>
              </form>
            </div>
            <span className="contact-your-localmanager">{t("contact-your-localmanager")} </span>
            <a className="call-911" href="tel:+911">{t("call-911")}</a>
          </div>
        </div>
        <Loader modalIsOpen={modalIsOpen} closeModal={closeModal} />
        <InstallModal modalIsOpen={installModal} closeModal={hideInstallPromotion} onInstall={onInstall} />
        <IosInstallModal modalIsOpen={showInstallMessage} closeModal={hideInstallPromotion} />
      </div>
    </React.Fragment>
  )
}
