import React from "react";
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { getInstallModalStyle } from '../../utils/GobalStyle';
import './InAppInstallation.css'
require('typeface-montserrat')

const styles = getInstallModalStyle();

const InstallModal = (props) => {
    const { t } = useTranslation()

    return (
        <Modal
            isOpen={props.modalIsOpen}
            onRequestClose={props.closeModal}
            style={styles}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
        >
            <p className="pop_up">{t('inAppMsg')}</p>
            <div className="btn_popup">
                <button className="rectanglebutton"
                    onClick={props.onInstall} >
                    <span className="submit"> {t('yes')}</span>
                </button>
                <button className="rectangleCancelButton"
                    onClick={() => {
                        localStorage.setItem("gaveInstallOption", 0)
                        props.closeModal();
                    }} >
                    <span className="cancel"> {t('no')}</span>
                </button>
            </div>
        </Modal>
    );
}
export default InstallModal;
