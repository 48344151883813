import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Home } from './components/Home';
import { LaundryForm } from './components/LaundryForm';
import { Success } from './components/Success';
import { Confirm } from './components/Confirm';

export const Routes = () => {
    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/confirm">
                <Confirm />
            </Route>
            <Route exact path="/laundryform">
                <LaundryForm />
            </Route>
            <Route exact path="/success">
                <Success />
            </Route>
        </Switch>

    )
}
