import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom"
import './LaundryForm.css';
import { Header } from "../Header";
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Swal from 'sweetalert2';
import cancelIcon from '../../assets/img/cancel_icon.png';

//...........API.............
import { equipmentService } from '../../api/equipmentService';
import { problemService } from '../../api/problemService';
import { uploadService } from '../../api/uploadService';
import { submitService } from '../../api/submitService';
import { getLocationDetails } from '../../utils/Auth';
import Loader from './../Loader/index';

require('typeface-montserrat')

export const LaundryForm = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [locDetails,] = useState(getLocationDetails())
    const [equipmentList, setEquipmentList] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [problemValue, setProblemValue] = useState("select...")
    const [equipmentValue, setEquipmentValue] = useState("select...")
    const [customerName, setCustomerName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [description, setDescription] = useState("")
    const [files, setFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [submitClicked, setSubmitClicked] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [problemList, setProblemList] = useState([])

    const openModal = () => {
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    const changeProblem = (e) => {
        setProblemValue(e.target.value)
    }
    const changeEquipment = (e) => {
        setEquipmentValue(e.target.value)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const yourData = {
            customerName: customerName,
            phoneNumber: phoneNumber,
            emailAddress: emailAddress,
            equipmentValue: equipmentValue,
            problemValue: problemValue,
            description: description
        }
        if (files.length === 0) {
            if (yourData.customerName.length !== 0 && yourData.phoneNumber !== null && yourData.emailAddress.length !== 0 && yourData.equipmentValue !== "select..." && yourData.problemValue !== "select...") {
                submitAsync([])
            }
        } else {
            if (yourData.customerName.length !== 0 && yourData.emailAddress.length !== 0 && yourData.phoneNumber !== null && yourData.equipmentValue !== "select..." && yourData.problemValue !== "select...") {
                let uploadData = []
                let i;
                for (i = 0; i < files.length; i++) {
                    uploadData.push(uploadPicturesAsync(uploadedFiles[i]))
                }
                Promise.all(uploadData)
                    .then(result => {
                        const updatedResult = result.filter(element => {
                            return element === false;
                        });
                        if (updatedResult && updatedResult.length <= 0) {
                            submitAsync(result)
                        } else {
                            submitAsync(result.filter(element => element !== false))
                        }
                    })
                    .catch(error => {
                        console.log("err in promise.all");
                    });
            }
        }
        setSubmitClicked(true)
    };
    const fileChangedHandler = (event) => {
        let filesArray = Array.from(event.target.files);

        if (filesArray.length !== 0) {
            const file = filesArray.map((eachFile) => {
                return URL.createObjectURL(eachFile)
            })
            let addedFiles = [...files, ...file]
            const newFiles = addedFiles.slice(0, 5);
            if (newFiles.length === 0 || newFiles.length <= 4) {
                setDisabled(false)
            } else {
                setDisabled(true);
            }
            setFiles(newFiles);
        }
        setUploadedFiles(event.target.files);

    }

    const deleteImage = (i) => {
        setDisabled(false)
        const filesValue = Object.assign([], files)
        filesValue.splice(i, 1)
        setFiles(filesValue)
    }
    const handleCancel = () => {
        Swal.fire({
            title: `<small> ${t('cancel_msg')} </small>`,
            showCancelButton: false,
            showDenyButton: true,
            confirmButtonText: `<small> ${t('yes')} </small>`,
            denyButtonText: `<small> ${t('no')} </small>`

        }).then((result) => {
            if (result.isConfirmed) {
                history.replace({ pathname: '/' });
            }
        })
    }
    const getEquipmentAsync = async () => {
        await equipmentService
            .getEquipmentDetails()
            .then(async (res) => {
                const equipmentData = res.data.Entities;
                setEquipmentList(equipmentData)
            })
            .catch((err) => {
                console.log(err)
            });

    };
    const getProblemAsync = async (objectCode, token) => {
        await problemService
            .getProblemDetails(objectCode, token)
            .then(async (res) => {
                const problemData = res.data.Entities;
                setProblemList(problemData);
            })
            .catch((err) => {
                console.log("Problem while fetching")
            });

    };
    const submitAsync = async (uploadedfiles) => {
        openModal()
        const requestJSON = {
            "locationID": locDetails?.id,
            "locationAreaID": locDetails?.locationAreaID,
            "customerName": customerName,
            "contactNumber": phoneNumber,
            "emailAddress": emailAddress,
            "equipmentTypeID": equipmentValue,
            "problemTypeID": problemValue,
            "description": description
        }
        const problemDetails = {
            "locationID": locDetails?.id,
            "locationAreaID": locDetails?.locationAreaID,
            "sourceCode": "CNTAPP",
            "requsetUserID": 2,
            "eventRequestJson": JSON.stringify(requestJSON),
            "files": uploadedfiles
        }
        await submitService
            .submitProblem(problemDetails)
            .then(async (res) => {
                closeModal()
                let submitData = res.data.Entities;
                if (res.data.Success && submitData.length !== 0) {
                    history.push("/success")
                }

            })
            .catch((err) => {
                closeModal()
            });
    };
    const uploadPicturesAsync = (filesValue) => {
        openModal()
        return new Promise(async (resolve, reject) => {
            await uploadService
                .uploadPictures(filesValue)
                .then(async (res) => {
                    resolve(res)
                })
                .catch((err) => {
                    console.log("Error while uploading image")
                    resolve(false)
                    closeModal()
                });
        });


    };

    useEffect(() => {
        getEquipmentAsync()
        getProblemAsync()

    }, [])

    return (
        <React.Fragment>
            <Header />
            <div className="laundry ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="contact-form">
                            <label className="col-sm-2">
                                <p className="tell-us-whats-wrong" data-testid="tellUs" > {t("tell_us")}</p>
                            </label>
                            <div className="form-group">
                                <label className="control-label col-sm-2">
                                    <span className="locaddress">
                                        {t("location")}</span>
                                </label>
                                <div className="col-sm-12">
                                    <input
                                        disabled={true}
                                        defaultValue={locDetails?.displayLongName}
                                        type="text"
                                        className="form-control loc-num"
                                        id="location"
                                        name="location"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-4" >
                                    <span className="customer-name"> {t("customerName")}</span>
                                </label>
                                <div className="col-sm-12">
                                    <input
                                        style={{
                                            borderColor: !submitClicked ? "#979797" : customerName === "" ? "#f29191" : "#979797"
                                        }}
                                        type="text"
                                        className="form-control customer-field"
                                        id="cname"
                                        placeholder={t("EnterCustomerName")}
                                        name="cname"
                                        onChange={(e) => { setCustomerName(e.target.value) }}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-4">
                                    <span className="contact-number">{t("contactNumber")}</span>
                                </label>
                                <div className="col-sm-12">
                                    <PhoneInput
                                        country={'us'}
                                        value={phoneNumber}
                                        onChange={pno => setPhoneNumber(pno)}
                                        className="phoneinput"
                                        inputStyle={{
                                            minWidth: "100%", width: "100%", height: "50px", boxShadow: "none",
                                            color: "#29293B", fontFamily: "Montserrat",
                                            borderColor: !submitClicked ? "#979797" : phoneNumber === null ? "#f29191" : "#979797"
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-4" >
                                    <span className="email-address"> {t("emailAddress")}</span>
                                </label>
                                <div className="col-sm-12">
                                    <input
                                        style={{
                                            borderColor: !submitClicked ? "#979797" : emailAddress === "" ? "#f29191" : "#979797"
                                        }}
                                        type="text"
                                        className="form-control email-field"
                                        id="email"
                                        placeholder={t("EnterEmailAddress")}
                                        name="email"
                                        onChange={(e) => { setEmailAddress(e.target.value) }}
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="control-label col-sm-2">
                                    <span className="equipment">{t("equipment")}</span>
                                </label>
                                <div className="col-sm-12">
                                    <select
                                        className="form-control equipment-name"
                                        id="equipment"
                                        onChange={changeEquipment}
                                        value={equipmentValue}
                                        style={{
                                            borderColor: !submitClicked ? "#979797" : equipmentValue === "select..." ? "#f29191" : "#979797"
                                        }}>
                                        <option value="select..." disabled className="select">{t("select")}</option>
                                        {
                                            equipmentList.map((equipment, i) => {
                                                return <option value={equipment.id} key={i}>{equipment.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-2">
                                    <span className="problem"> {t("problem")}</span>
                                </label>
                                <div className="col-sm-12">
                                    <select
                                        className="form-control problem-name"
                                        id="problem"
                                        onChange={changeProblem}
                                        value={problemValue}
                                        style={{
                                            borderColor: !submitClicked ? "#979797" : problemValue === "select..." ? "#f29191" : "#979797"
                                        }}>
                                        <option value="select..." disabled className="select">{t("select")}</option>
                                        {
                                            problemList.map((problem, i) => {
                                                return <option value={problem.id} key={i}>{problem.name}</option>

                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label style={{ marginLeft: "14px" }}>
                                    <span className="please-elaborate-the"> {t("elaborate")}</span>
                                </label>
                                <div className="col-sm-12">
                                    <textarea
                                        className="form-control elaborate-text"
                                        placeholder={t("text_here")}
                                        rows={3}
                                        maxLength={500}
                                        defaultValue=""
                                        id="prbm"
                                        onChange={(e) => { setDescription(e.target.value) }}
                                    >
                                    </textarea>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                {
                                    files && files.length > 0 &&
                                    <div className="row">
                                        {files.map((file, i) => {
                                            return (
                                                <div className="col-6 pr-0" key={i}>
                                                    <img src={file} className="file-img" alt="not found" />
                                                    <img src={cancelIcon} alt='cancel_icon'
                                                        className="cancel-icon"
                                                        onClick={() => { deleteImage(i) }} />
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                }
                            </div>
                            <div className="col-sm-12">
                                <label
                                    className="image-upload"
                                    style={{
                                        border: disabled ? "2px solid #999999" : "2px solid #0091FF",
                                        color: disabled ? "#999999" : "#0091FF",
                                        backgroundColor: disabled ? "#dddddd" : "#FFFFFF",
                                    }}
                                    htmlFor="filePicker"
                                >
                                    {t("uploadPictures")}
                                </label>
                                <input id="filePicker" disabled={disabled}
                                    style={{ visibility: "hidden" }} type={"file"}
                                    multiple
                                    onChange={fileChangedHandler}
                                    accept="image/*">
                                </input>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-12 buttonscontainer">
                                    <button className="rectanglesearch"
                                        type={"button"}
                                        onClick={handleCancel}
                                    >
                                        <span className="cancel"> {t("cancel")} </span></button>
                                    <button className="rectanglesub"
                                        onClick={handleSubmit} type="submit" >
                                        <span className="submit"> {t("submit")}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Loader modalIsOpen={modalIsOpen} closeModal={closeModal} />
            </div>
        </React.Fragment >
    )
}
