import axios from "axios";
import { getAuthorization } from "../utils/Auth"


async function submitProblem(postParameter) {
  const url = `${process.env.REACT_APP_BASE_URL}/events`;

  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        postParameter,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
            Authorization: getAuthorization()
          },
        },
      )
      .then((response) => {
        if (response != null) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const submitService = {
  submitProblem
};
